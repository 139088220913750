import { Grid } from '@mui/material';
import styled from 'styled-components';
import constants from '../../constants';
import { Link } from 'react-router-dom';
import { TranslateByKey } from '../shared/Translate';
import { useAppContext } from '../../Context';

export type MultimediaDataType = { type: string, url: string, description: string, poster?:string };

export type TeamMemberDataType = {
    name: string;
    position: string;
    experience: { es: string, en: string } | null;
    description: { es: string, en: string } | null;
    links: { name: string, url: string }[] | null;
    image: string;
    slug: string;
    logo?: string;
    company_position?: string;
    multimedia?: MultimediaDataType[];
}


const TeamMember = ({ team }: { team: TeamMemberDataType }) => {
    const { language } = useAppContext();
    // include image from assets/images/team
    const imagePath = require(`../../assets/images/team/${team.image}`);

    return (
        <TeamMemberContainer>
            <img src={imagePath} alt={team.name} />
            <h3>{team.name}</h3>
            <h4>{team.position}</h4>
            <p className='experience'>{language === 'es' ? team.experience?.es : team.experience?.en}</p>
            <p>{language ==='es' ? team.description?.es : team.description?.en}</p>
            <LinksContainer>
                {team.links?.map((link, index) => (
                    <a href={link.url} key={index} target="_blank" rel="noreferrer">{link.name}</a>
                ))}
            </LinksContainer>
            <br/>
            {team.slug && <Link to={`/team/${team.slug}`} className='portfoli-btn'>
                <TranslateByKey stringKey='portfolio' />
            </Link>}
        </TeamMemberContainer>
    );
}

const TeamMembers: React.FC<{ teamData: TeamMemberDataType[] }> = ({ teamData }) => {
    return (
        <TeamMembersContainer container>
            {teamData.map((team, index) => (
                    <TeamMember team={team} key={index} />
            ))}
        </TeamMembersContainer>
    );
}

export default TeamMembers;

const TeamMemberContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: ${constants.colors.black};
    border-radius: 25px;
    margin: 10px;
    flex: 1;
    min-height: 700px;

    h3 {
        margin: 0;
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        color: #3c4160;
        position: relative;
        margin-bottom: 20px;
            &:after {
                content: '';
                display: block;
                width: 50%;
                height: 2px;
                background-color: ${constants.colors.green};
                margin: 10px auto;
                position: absolute;
                left: 0;
                right: 0;
            }
    }
    h4 {
        font-size: 1.1em;
        text-transform: uppercase;
        text-align: center;
        margin: 10px 0;
        display: block;
        min-height: 40px;
    }
    p.experience {
        margin: 0;
        font-size: 1.1em;
        font-weight: 700;
    }

    p {
        margin: 10px 0;
        text-align: center;
        font-size: 1.1em;
        font-weight: 500;
    }

    img {
        width: 80%;
        border-radius: 10px;
        margin: auto;
    }

    a.portfoli-btn {
        background: ${constants.colors.green};
        color: white;
        padding: 15px 10px;
        width: 180px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        text-decoration: none;
        border-radius: 25px;
        margin-top: 20px;
        transition: 0.3s ease all;
    }

    a.portfoli-btn:hover {
        background: ${constants.colors.greenDark};
    }

    @media (max-width: 1114px) {
        min-height: 500px;
        /* 2 POR FILA */
        flex: 1 0 30%;
        flex-basis: 30%;
    }

    @media (max-width: 768px) {
        min-height: 500px;
        /* 1 POR FILA */
        flex: 1 0 100%;
        flex-basis: 100%;
    }
`;

const TeamMembersContainer = styled(Grid)`
    margin-top: 50px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    div:nth-child(even){
        background-color: ${constants.colors.bluelight};
    }
`;

const LinksContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 10px;

    a {
        color: ${constants.colors.blueshine} !important;
        font-weight: 700;
        text-decoration: none;
        text-align: center;
        display: block;
        font-size: 0.9em;
        &:hover {
            text-decoration: underline;
        }
    }
`;
