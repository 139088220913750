import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MenuItemType, useAppContext } from './Context';

import Navbar from './components/Navbar';
import LanguageSelector from './components/LanguageSelector';

import ScrollTo from './components/ScrollTo';

import './App.css';
import Footer from './components/shared/Footer';
import TeamMember from './pages/TeamMember';

const App : React.FC = () => {
    const { menuItems } = useAppContext();

    return (
        <>
        <BrowserRouter>
            <ScrollTo />
            <Navbar />
            <LanguageSelector />
            <Routes>
                {menuItems.map((menuItem : MenuItemType, index) => (
                    <Route key={index} path={menuItem.to} element={menuItem.component} />
                ))}
                <Route path="team/:member" element={<TeamMember />} />
            </Routes>
        </BrowserRouter>
        <Footer />
        </>
    );
}

export default App;