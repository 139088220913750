import { useParams } from "react-router-dom"

import styled from "styled-components"
import constants from "../constants"

import { teamData } from "./Team";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { MultimediaDataType } from "../components/team/TeamMembers";
 
const TeamMember : React.FC = () => {
    const { member } = useParams();

    const memberData = teamData.find((m) => m.slug === member);
    const [imagePath, setImagePath] = useState('');
    const [logoPath, setLogoPath] = useState('');
    const [multimedia, setMultimedia] = useState<MultimediaDataType[]>([]);

    useEffect(() => {
        if (memberData) {
            const image = require(`../assets/images/team/${memberData.image}`);
            setImagePath(image);
            if (memberData.logo) {
                const logo = require(`../assets/images/${memberData.logo}`);
                setLogoPath(logo);
            }

            if (memberData.multimedia) {
                const multimediaArray: MultimediaDataType[] = [];
                memberData.multimedia.forEach((media : MultimediaDataType) => {
                    if (media.type === 'image') {
                        const image = require(`../assets/images/team/multimedia/${media.url}`);
                        multimediaArray.push({ type: 'image', url: image, description: media.description });
                    }else{
                        if (media.poster) {
                            const poster = require(`../assets/images/team/multimedia/${media.poster}`);
                            multimediaArray.push({ type: 'video', url: media.url, description: media.description, poster: poster });
                        }else{
                            multimediaArray.push({ type: 'video', url: media.url, description: media.description });
                        }
                    }
                });
                setMultimedia(multimediaArray);

            }
            
        }
    }, [memberData]);

    return (
        <TeamMemberContainer>
            <TeamMemberTitleContainer container>
                <Grid item xs={12} sm={2} className="center">
                    <img src={logoPath} alt="logo" className='logo' />
                </Grid>
                <Grid item xs={12} sm={8}>
                <h2>PORTAFOLIO EXPERIENCIA</h2>
                <h3>{memberData?.name} <span className="member-position">{memberData?.company_position}</span></h3>
                </Grid>
                <Grid item xs={12} sm={2} className="foto-container">
                    <img src={imagePath} alt="foto" className='foto' />
                </Grid>
            </TeamMemberTitleContainer>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    {memberData?.multimedia && multimedia.map((media, index) => (
                        <MultimediaItem key={index}>
                            {media.type === 'image' ? <img src={media.url} alt={media.description} /> : <video controls src={media.url} poster={media.poster} />}
                            <div>{media.description}</div>
                        </MultimediaItem>
                    ))}
                </Grid>
            </Grid>
        </TeamMemberContainer>
        
    );
}

export default TeamMember;

const MultimediaItem = styled.div`
    margin: 30px 0;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    img, video {
        width: 80%;
        max-width: 80%;
    }

    div {
        position: absolute;
        bottom: 0;
        background-color: ${constants.colors.blueGray};
        color: ${constants.colors.white};
        width: 70%;
        border-radius: 25px 25px 0 0;
        padding: 10px;
        left:0;
        right:0;
        margin: auto;
        font-weight: 700;
    }


`;

const TeamMemberContainer = styled.div`
    width: 100%;
    margin-top: 80px;
`;

const TeamMemberTitleContainer = styled(Grid)`
    padding: 10px 0;
    margin: 120px auto 80px auto;
    background: ${constants.colors.blueGray};
    color: ${constants.colors.white};
    text-align: center;
    border-radius:25px;
    max-width: 90%;
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 300px;
        margin: 0 auto;
        max-width: 80%;
    }

    .foto-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        
        .foto {
            width: 260px;
            max-width: 90%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 2;
        }
    }

    h3{
        margin: 0;
        font-size: 1.2em;
        text-transform: uppercase;
        font-weight: 700;
        text-align: center;
        position: relative;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 2em;
        text-transform: uppercase;
    }

    h2::after {
        content: '';
        display: block;
        width: 300px;
        max-width: 80%;
        height: 2px;
        background-color: ${constants.colors.green};
        margin: 10px auto;
        position: absolute;
        left: 0;
        right: 0;
    }

    .member-position {
        font-size: .8em;
        text-transform: uppercase;
        font-weight: 400;
    }
`;
