import { useState } from 'react';
import { MenuItemType, useAppContext } from '../Context'; 

import { Grid } from '@mui/material';

import styled from "styled-components";

import { Link } from 'react-router-dom';

import Translate  from './shared/Translate';

import constants from '../constants';

import logoDark from '../assets/images/logo-dark.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar : React.FC = () => {

    const { menuItems, activeMenuItem, setActiveMenuItem, pageActive, setPageActive } = useAppContext();
    const [openMenu, setOpenMenu] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    window.addEventListener('resize', () => {
        setWindowWidth(window.innerWidth);
    });

    const showMenu = () => {
        if (windowWidth > 920) {
            return true;
        }else{
            return openMenu;
        }
    }

    const handleOnClick = (to: string) => {
        if (to !== '/') {
            setPageActive(true);
            setActiveMenuItem(to);
        }else{
            // scroll to top smooth
            window.scrollTo({top: 0, behavior: 'smooth'});
    
            setActiveMenuItem('');
            setPageActive(false);
        }

        if (windowWidth <= 920) {
            setOpenMenu(false);
        }
    };

    return <NavbarContainer alignItems='center' className='navbar'>
                <LogoContainer item xs={2} className='logo-container'>
                    <Logo src={logoDark} alt="logo" />
                </LogoContainer>
                {showMenu() && <MenuContainer item xs={8} className={pageActive ? 'active' : ''}>
                        {menuItems.map((menuItem : MenuItemType, index) => (
                            <MenuItem key={index}>
                                <Link 
                                    to={menuItem.to} 
                                    onClick={() => handleOnClick(menuItem.to)} 
                                    className={menuItem.to === activeMenuItem ? 'active' : ''}> <Translate stringText={menuItem.label} />
                                </Link>
                            </MenuItem>
                        ))}
                </MenuContainer>}
                <MenuMobileButton onClick={() => setOpenMenu (!openMenu)}>
                    <FontAwesomeIcon  icon={openMenu ? faTimes : faBars} />
                </MenuMobileButton>
            </NavbarContainer>;
}

export default Navbar;

const NavbarContainer = styled(Grid)`
    background-color: ${constants.colors.green};
    color: ${constants.colors.white};
    padding: 10px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.65);
`;

const LogoContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    width: 100px;
`;

const MenuContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${constants.colors.greenDark};
    border-radius: 50px;
    flex-direction: row;
    &.active {
        background-color: transparent;
    }
    @media (max-width: 920px) {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        flex-direction: column !important;
        width: 80%;
        background: ${constants.colors.green};
        box-shadow: 0px 5px 3px rgba(0,0,0,0.65);
        top: 100%;
        right: 0;
        border-radius: 0px;
        &.active {
            background-color: ${constants.colors.green};
        }
    }
`;

const MenuItem = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    a {
        color: ${constants.colors.white};
        text-decoration: none;
        font-size: 1.2em;
        font-weight: 600;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        text-transform: uppercase;
        text-align: center;
        &.active {
            background-color: ${constants.colors.greenDark};
        }
    }
    &:last-child a.active{
        border-radius: 0px 50px 50px 0px;
    }
    &:first-child a.active{
        border-radius: 50px 0px 0px 50px;
    }
    @media (max-width: 920px) {
        display:block;
        width: 100%;
        text-align: center;
        a {
            padding: 5px 0;
            border-bottom: 1px solid ${constants.colors.greenDark};
        }
        &:last-child a.active{
            border-radius: 0px;
        }
        &:first-child a.active{
            border-radius: 0px;
        }
        &:last-child a{
            border-bottom: none;
        }
    }
`;

const MenuMobileButton = styled('div')`
    display: none;
    color: ${constants.colors.greenDark};
    @media (max-width: 920px) {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 2em;
    }
`;
