import { Divider, Grid } from '@mui/material';

import styled from "styled-components";
import constants from '../../constants';

import logoDark from '../../assets/images/logo-dark.png';
import directLogo from '../../assets/images/direct-logo.png';
import cayahomesLogo from '../../assets/images/cayahomes-logo.png';
import valderramaholdingLogo from '../../assets/images/valderramaholding-logo.png';
import construsegoLogo from '../../assets/images/construsego-logo.png';
import  { TranslateByKey, TranslateComponent } from '../shared/Translate';

import backOurcompany from '../../assets/images/back-ourcompany.jpg';
import equipoIcon from '../../assets/images/equipo-icon.png';
import teamIcon from '../../assets/images/team-icon.png';
import buildmoneyIcon from '../../assets/images/buildmoney-icon.png';
import handdiamondIcon from '../../assets/images/handdiamond-icon.png';
import moneyIcon from '../../assets/images/money-icon.png';
import backIndicators from '../../assets/images/back-indicators.jpg';

import { useAppContext } from "../../Context";

const AboutUs : React.FC = () => {
    const { language } = useAppContext();
    return (
        <>
        <AboutUsWrapper id="nosotros" container alignItems="center">
            <TopTriangle />
            <Grid item xs={12} className='text-center'>
                <Logo src={logoDark} alt="logo" />
            </Grid>
            <AboutUsText item xs={12} >
                <TranslateComponent stringKey='aboutus-one' />
            </AboutUsText>
            <Grid item xs={12} className='text-center'>
                <LogoClienteContainer container spacing={3}>
                    <Grid item sm={3} xs={6} className='text-center'>
                        <LogoCliente src={directLogo} alt="logo" />
                    </Grid>
                    <Grid item sm={3} xs={6} className='text-center'>
                        <LogoCliente src={cayahomesLogo} alt="logo" />
                    </Grid>
                    <Grid item sm={3} xs={6} className='text-center'>
                        <LogoCliente src={valderramaholdingLogo} alt="logo" />
                    </Grid>
                    <Grid item sm={3} xs={6} className='text-center'>
                        <LogoCliente src={construsegoLogo} alt="logo" />
                    </Grid>
                </LogoClienteContainer>
                <GreenDivider />
            </Grid>
            <BottomTriangle />
        </AboutUsWrapper>
        <OurCompanyContainer>
                <LogoZone>
                    <Logo src={logoDark} alt="logo" />
                </LogoZone>
                <OurCompanyText>
                    <TranslateComponent stringKey='ourcompany-title' />
                    <br />
                    <TranslateComponent stringKey='ourcompany-text' />
                </OurCompanyText>
        </OurCompanyContainer>
        <ExperienceContainer id="experiencia">
            <ExperienceTitle>
                <TranslateByKey stringKey='experience-title' />
            </ExperienceTitle>
            <EquipoIcon src={language === 'es' ? equipoIcon : teamIcon} alt="equipo" />
            <ExperienceText>
                <TranslateComponent stringKey='experience-text' />
            </ExperienceText>
        </ExperienceContainer>
        <IndicatorsContainer container >
            <IndicatorItem item lg={3} sm={6} xs={12}>
                <IndicatorIcon src={buildmoneyIcon} alt="Unidades" />
                <TranslateComponent stringKey='indicator-one' />
            </IndicatorItem>
            <IndicatorItem item lg={4} sm={6} xs={12}>
                <IndicatorIcon src={handdiamondIcon} alt="area" />
                <TranslateComponent stringKey='indicator-two' />
            </IndicatorItem>
            <IndicatorItem item lg={5} sm={12} xs={12}>
                <IndicatorIcon src={moneyIcon} alt="ventas" />
                <TranslateComponent stringKey='indicator-three' />
            </IndicatorItem>
        </IndicatorsContainer>
        </>
    );
}

export default AboutUs;

const Logo = styled.img`
    width: 200px;
`;

const AboutUsWrapper = styled(Grid)`
    padding: 50px 40px;
    position: relative;
`;

const AboutUsText = styled(Grid)`
    text-align: justify;
    padding: 20px;
    width: 80% !important;
    margin: 0 auto;
    line-height: 1.5em;
`;

const TopTriangle = styled.div`
    width: 0;
    height: 0;
    border-left: 100px solid ${constants.colors.blue};
    border-top: 0px solid transparent;
    border-bottom: 150px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
`;

const BottomTriangle = styled.div`
    width: 0;
    height: 0;
    border-right: 100px solid ${constants.colors.blue};
    border-top: 150px solid transparent;
    border-bottom: 0px solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
   
`;

const LogoCliente = styled.img`
    max-width: 100%;
    width: 200px;
    margin: 0 auto;
`;

const LogoClienteContainer = styled(Grid)`
    margin: 20px auto !important;
    width: 60% !important;
    @media (max-width: 920px) {
        width: 100% !important;
    }
`;

const GreenDivider = styled(Divider)`
    background-color: ${constants.colors.green};
    height: 2px;
    margin: 20px auto !important;
    width: 60%;
    @media (max-width: 920px) {
        width:80%;
    }
`;

const OurCompanyContainer = styled.div`
    padding: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88%;
    @media (max-width: 1340px) {
        width: 75%;
    }
    @media (max-width: 1050px) {
        padding: 20px;
        flex-direction: column;
        width: 80%;
    }

`;

const LogoZone = styled.div`
    background-color: ${constants.colors.white};
    border-radius: 50px 0 0 50px;
    width: 510px;
    border: 2px solid ${constants.colors.blue};
    border-right: none;
    height: 300px;
    position:relative;
    margin-left: 20px;
    img {
        width: 120px;
        background-color: ${constants.colors.white};
        position: absolute;
        left: -75px;
        margin: auto;
        top: 0;
        bottom: 0;
        padding:20px
    }
    @media (max-width: 1340px) {
        img {
            width: 100px;
        }
    }
    @media (max-width: 1050px) {
        height: 120px;
        border-radius: 50px 50px 0 0;
        width: 80%;
        margin: 0 auto;
        border-right: 2px solid ${constants.colors.blue};
        border-bottom: none;
        img {
           left:0;
            right: 0;
            margin: 0 auto;
            top:-70px;
            width: 150px;
        }
    }

    @media (max-width: 768px) {
        height: 60px;
        border-radius: 50px 50px 0 0;
        width: 80%;
        margin: 0 auto;
        border-right: 2px solid ${constants.colors.blue};
        border-bottom: none;
        img {
           left:0;
            right: 0;
            margin: 0 auto;
            top:-70px;
            width: 150px;
        }
    }
`;

const OurCompanyText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: justify;
    padding: 50px;
    background: url(${backOurcompany}) no-repeat center center;
    background-size: cover;
    color: ${constants.colors.white};
    h2 {
        font-size: 1.6em;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 10px;
    }
    p {
        font-size: 1.2em;
        margin-bottom: 20px;
        width: 80%;
        margin: 0 auto;
    }
    @media (max-width: 1050px) {
        width: 100%;
        padding: 40px;
        h2 {
            font-size: 1.2em;
        }
    }
`;


const ExperienceContainer = styled.div`
    padding: 20px 0px;
    position: relative;
`;

const ExperienceTitle = styled.h2`
    text-align: left;
    margin-bottom: 20px;
    text-transform: uppercase;
    padding-left: 40px;
    color: ${constants.colors.blue};
    font-size: 1.8em;
    position: relative;
    width: fit-content;
    &::before {
        content: '';
        width: 105%;
        height: 3px;
        background-color: ${constants.colors.green};
        position: absolute;
        bottom: -5px;
        left:0;
    }
`;

const ExperienceText = styled.div`
    text-align: justify;
    padding: 20px;
    width: 80%;
    font-size: 1.1em;
    line-height: 1.5em;
    margin: 0 auto;
    @media (max-width: 1050px) {
        padding: 10px;
    }
`;

const EquipoIcon = styled.img`
    width: 200px;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    @media (max-width: 768px) {
        left:auto;
        right: 20px;
    }
`;

const IndicatorsContainer = styled(Grid) `
    padding: 50px 0px;
    background: url(${backIndicators}) no-repeat center center;
    background-size: cover;
    color: ${constants.colors.white};
    text-align: center;
`;

const IndicatorItem = styled(Grid)`
    padding: 20px;
    text-align: center;
    p {
        font-size: 1.2em;
        margin: 10px 0;
    }
`;

const IndicatorIcon = styled.img`
    width: 100px;
    margin: 0 auto;
`; 