import { ReactNode } from "react"
import styled from "styled-components"
import constants  from "./constants"
export const langBook : Record<string, Record<string,string>>= {
    "titlebanner" : {
        "es" : "¡Invierte con nosotros y haz realidad tu sueño Americano!",
        "en" : "Invest with us and make your American dream come true!"
    },
    "experience-title" : {
        "es" : "Experiencia",
        "en" : "Experience"
    },
    "team-title" : {
        "es" : "Equipo Directivo",
        "en" : "Management Team"
    },
    "portfolio" : {
        "es" : "Portafolio",
        "en" : "Portfolio"
    },
}

const BlueHighlight = styled.span`
    background-color: ${constants.colors.blue};
    color: ${constants.colors.white};
    padding: 3px;
`;

const WhiteHighlight = styled.span`
    background-color: ${constants.colors.white};
    color: ${constants.colors.blue};
    padding: 3px;
    font-weight: bold;
    font-size: 1.2em;
    line-height: 1.5em;
`;

const GreenText = styled.span`
    color: ${constants.colors.green};
`;

export const langBookComponent : Record<string, Record<string,ReactNode>>= {
    "aboutus-one" : {
        "es" : <><p>
        Somos la firma de <BlueHighlight>INVERSIÓN EN BIENES RAICES</BlueHighlight> más rentable y segura de corto y mediano plazo en el Estado de la Florida - Estados Unidos</p><p>Todos los socios fundadores cuentan con una <BlueHighlight>experiencia de más de 25 años</BlueHighlight> en el mercado de la construcción, planificación y estructuración financiera, compra de propiedades en las mejores localizaciones, optimización en la rentabilidad de venta de unidades residenciales y multifamiliares enfocado a la renta como uso final, en el Estado de Florida - Estados Unidos, Toronto - Canadá y Colombia, con más de 4.000 unidades entregadas residenciales y multifamiliares, <BlueHighlight>con la más alta garantía y satisfacción de nuestros clientes</BlueHighlight>, como también inversionistas, bancos asociados y proveedores.
        </p></>,
        "en" : <><p>
        We are the most profitable and safe <BlueHighlight>REAL ESTATE INVESTMENT</BlueHighlight> firm in the short and medium term in the State of Florida - United States
        </p><p>All founding partners have <BlueHighlight>more than 25 years of experience</BlueHighlight> in the construction market, financial planning and structuring, property purchase in the best locations, optimization in the profitability of residential and multifamily unit sales focused on rental as the final use, in the State of Florida - United States, Toronto - Canada and Colombia, with more than 4,000 residential and multifamily delivered units, <BlueHighlight>with the highest guarantee and satisfaction of our clients</BlueHighlight>, as well as investors, associated banks and suppliers.
        </p></>
    },
    "ourcompany-title" : {
        "es" : <h2>NUESTRA COMPAÑÍA OFRECE<br/>UNA ATRACTIVA OPORTUNIDAD<br/><GreenText>DE INVERSIÓN EN ACTIVOS INMOBILIARIOS</GreenText></h2>,
        "en" : <h2>OUR COMPANY OFFERS<br/>AN ATTRACTIVE OPPORTUNITY<br/><GreenText>FOR INVESTMENT IN REAL ESTATE ASSETS</GreenText></h2>
    },
    "ourcompany-text" : {
        "es" : <p>
        Generamos el máximo de valor agregado para nuestros socios e inversores, a través de nuestro modelo de negocio que garantiza un retorno de la inversión a corto y mediano plazo, con un mínimo de riesgo. El éxito del desarrollo de los proyectos multifamiliares se basa en el conocimiento y la experiencia para la gestion de activos inmobiliarios, la cual está basada en nuestra amplia trayectoria, experiencia, políticas de cumplimiento total y acompañamiento personalizado en todas y cada una de sus facetas, para alcanzar el sueño Americano. 
        </p>,
        "en" : <p>
        We generate the maximum added value for our partners and investors, through our business model that guarantees a return on investment in the short and medium term, with minimal risk. The success of the development of multifamily projects is based on knowledge and experience for the management of real estate assets, which is based on our extensive trajectory, experience, full compliance policies and personalized accompaniment in each and every one of its facets, to achieve the American dream.
        </p>
    },
    "experience-text" : {
        "es" : <><p>
            25 años de experiencia personal y profesional de nuestros socios fundadores, logran consolidar un equipo de trabajo altamente capacitado en planificación, diseño, financiación, construcción, operación y venta de activos inmobiliarios, en las mejores localizaciones, en todo el Estado de Florida, Estados Unidos.</p> <p>Contamos con Aliados Estratégicos en el sector inmobiliario para la compra y venta de propiedades para la renta como uso final. La fortaleza de <BlueHighlight>nuestro equipo minimiza los tiempos de diseño y permisología</BlueHighlight> en nuestros proyectos garantizando un retorno de la inversión más la rentabilidad agregada.</p></>,
        "en" : <><p>
            25 years of personal and professional experience of our founding partners, manage to consolidate a highly trained work team in planning, design, financing, construction, operation and sale of real estate assets, in the best locations, throughout the State of Florida, United States.</p><p>We have Strategic Allies in the real estate sector for the purchase and sale of properties for rent as a final use. The strength of <BlueHighlight>our team minimizes the design and permitting times</BlueHighlight> in our projects, guaranteeing a return on investment plus added profitability.</p></>
    },
    "indicator-one" : {
        "es" : <p>Contamos con más de<br/><WhiteHighlight>4.000 UND</WhiteHighlight><br/>inmobiliarias<br/>desarrolladas.</p>,
        "en" : <p>We have more than<br/><WhiteHighlight>4,000</WhiteHighlight><br/>real estate units<br/>developed.</p>
    },
    "indicator-two" : {
        "es" : <p>Alcanzamos más de<br/><WhiteHighlight>3'000.000 SF<sup>2</sup></WhiteHighlight><br/>en proyectos inmobiliario tipo<br/>multifamiliares en Estados<br/>Unidos, Canadá y Colombia</p>,
        "en" : <p>We reach more than<br/><WhiteHighlight>3'000.000 SF<sup>2</sup></WhiteHighlight><br/>in multifamily real estate<br/>projects in the United States,<br/>Canada and Colombia</p>
    },
    "indicator-three" : {
        "es" : <p>En nuestros roles empresariales y profesionales<br/>previos, hemos planificado, construido y vendido<br/>viviendas residenciales y multifamiliares en<br/>Estados Unidos, Canadá y Colombia.<br/><WhiteHighlight>USD 500.000.000 VENTAS</WhiteHighlight></p>,
        "en" : <p>In our previous business and professional roles,<br/>we have planned, built and sold residential and<br/>multifamily homes in the United States, Canada<br/>and Colombia.<br/><WhiteHighlight>USD 500,000,000 SALES</WhiteHighlight></p>
    },
    "eslogan" : {
        "es" : <p>¡Invierte con nosotros <br/>y haz realidad tu sueño Americano!</p>,
        "en" : <p>Invest with us <br/>and make your American dream come true!</p>
    }
}


