import { Grid } from '@mui/material';
import constants from '../../constants';
import styled from 'styled-components';

import logo from '../../assets/images/logo-dark.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGlobe, faMapMarker} from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { TranslateComponent } from './Translate';

const Footer : React.FC = () => {
    return (
        <FooterContainer container>
            <Grid item sm={12} lg={5} className='border-right-green'>
                <img src={logo} alt="logo" className='logo' />
               <TranslateComponent stringKey="eslogan" />
            </Grid>
            <Grid item sm={12} lg={7}>
                <div className='social-container'>
                    <SocialIcon href="mailto:MIAMIINVESTMENTSANDSOLUTIONS@GMAIL.COM" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </SocialIcon>
                    <SocialIcon href="https://www.instagram.com/"
                        target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </SocialIcon>
                    <SocialIcon href="http://www.miamiinvestmentsandsolutions.com/">
                        <FontAwesomeIcon icon={faGlobe} />
                    </SocialIcon>
                </div>
                <p>
                    <a href="http://www.miamiinvestmentsandsolutions.com/">www.<span className='enphasis'>miamiinvestmentsandsolutions</span>.com</a>
                </p>
                <p>
                    <a href="mailto:MIAMIINVESTMENTSANDSOLUTIONS@GMAIL.COM">
                    MIAMIINVESTMENTSANDSOLUTIONS@GMAIL.COM
                    </a>
                </p>
                <p>
                    <SocialIcon
                    href="https://wa.me/573156775190"
                    target="_blank"
                    rel="noreferrer"
                    >
                        <FontAwesomeIcon icon={faWhatsapp} />
                        
                    </SocialIcon>
                    <a href="https://wa.me/573156775190" target="_blank" rel="noreferrer" className='whatsapp-link'>
                        315 677 5190
                    </a>
                </p>
                <br/>
                <p>
                    <span className='address-text'><FontAwesomeIcon icon={faMapMarker} />{' '}
                    19790 WEST DIXIE HWY MIAMI, FL 33180 - OFFC UNIT 607
                    </span>
                </p>
            </Grid>
        </FooterContainer>
    );
    }

export default Footer;

const FooterContainer = styled(Grid)`
    background-color: ${constants.colors.gray};
    color: ${constants.colors.blue};
    padding: 20px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
    position: relative;
    bottom: 0;
    width: 100%;
    justify-content: center;

    .logo {
        width: 350px;
        max-width: 100%;
    }

    .border-right-green{
        position: relative;
        padding: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .border-right-green::after {
        content: '';
        position: absolute;
        right: 0;
        top: 10%;
        width: 5px;
        height: 80%;
        background-color: ${constants.colors.green};

        @media (max-width: 768px) {
            width: 80%;
            height: 5px;
            bottom: 0;
            right: 10%;
            top: auto;
        }
    }

    .social-container {
        margin: 20px 0;
    }

    .enphasis {
        text-transform: uppercase;
        font-weight: bold;
    }

    a {
        color: ${constants.colors.blue};
        text-decoration: none;
    }

    .whatsapp-link {
        display: inline-block;
        margin: 0 10px;
        font-size: 1.3em;
        transition: all 0.3s;
        font-weight:500;
        color: ${constants.colors.blue} !important;

        @media (max-width: 768px) {
            font-size: 1.2em;
        }
    }

    .address-text {
        display: inline-block;
        margin: 0 10px;
        font-size: 1.1em;
        transition: all 0.3s;
        font-weight:500;
        color: ${constants.colors.blue} !important;

        @media (max-width: 768px) {
            font-size: 1.2em;
        }
    }

    p {
        margin: 5px 0;
        max-width: 100%;
        word-break: break-all;

        @media (max-width: 768px) {
            font-size: .8em;
        }
    }
`;

const SocialIcon = styled.a`
    display: inline-block;
    background-color: ${constants.colors.blue};
    font-size: 1.8em;
    margin: 0 10px;
    transition: all 0.3s;
    padding: 5px;
    color: ${constants.colors.white} !important;
    border-radius: 50px;
    width: 50px;
    height: 50px;

    &:hover {
        color: ${constants.colors.green};
    }

    @media (max-width: 768px) {
        font-size: 1.5em;
        width: 40px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

    }
`;