import { createContext, ReactNode , useContext, useState } from 'react';
import Home from './pages/Home';
import Team from './pages/Team';
import WhyInvest from './pages/WhyInvest';
import FundOne from './pages/FundOne';

type ContextType = {
    menuItems: MenuItemType[];
    setMenuItems: (menuItems: MenuItemType[]) => void;
    activeMenuItem: string;
    setActiveMenuItem: (activeMenuItem: string) => void;
    language: string;
    setLanguage: (language: string) => void;
    pageActive: boolean;
    setPageActive: (pageActive: boolean) => void;
};

export type MenuItemType = {
    label: StringMultilingualType
    to: string;
    component: ReactNode;
    isActive: boolean;
};

export type StringMultilingualType = {
    es: string;
     en: string;
};

const Context = createContext<ContextType | undefined>(undefined);

export const useAppContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};

type AppProviderProps = {
    children: ReactNode;
};

const MenuItems : MenuItemType[] = [
    { label: { es: 'Inicio', en: 'Home' }, to: '/', isActive: true, component: <Home /> },
    { label: { es: 'Nosotros', en: 'About us' }, to: '/#nosotros', isActive: false , component: null },
    { label: { es: 'Experiencia', en: 'Experience' }, to: '/#experiencia', isActive: false , component: null },
    { label: { es: 'Equipo', en: 'Team' }, to: '/equipo', isActive: false , component: <Team /> },
    { label: { es: '¿Por qué invertir?', en: 'Why invest?' }, to: '/por-que-invertir', isActive: false , component: <WhyInvest /> },
    { label: { es: 'Fondo I', en: 'Fund 1' }, to: '/contacto', isActive: false , component: <FundOne /> },
];

export const AppProvider = ({ children }: AppProviderProps) => {
    const [menuItems, setMenuItems] = useState<MenuItemType[]>(MenuItems);
    const [activeMenuItem, setActiveMenuItem] = useState<string>('/');
    const [language, setLanguage] = useState<string>('es');
    const [pageActive, setPageActive] = useState<boolean>(false);

    const value = {
        menuItems,
        setMenuItems,
        activeMenuItem,
        setActiveMenuItem,
        language,
        setLanguage,
        pageActive,
        setPageActive,
    };

    return <Context.Provider value={value}>{children}</Context.Provider>;
};


