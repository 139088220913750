import styled from 'styled-components';

import logo from '../assets/images/logo-light.png';
import banner from '../assets/images/banner.jpg';

import { TranslateByKey } from '../components/shared/Translate';
import AboutUs from '../components/home/AboutUs';

const Home : React.FC = () => {
    return (
        <WrapperContent>
            <Banner>
                <img src={logo} alt="logo" />
                <h2><TranslateByKey stringKey='titlebanner' /></h2>
            </Banner>
            <AboutUs />
        </WrapperContent>
        
    );
}
export default Home;

const WrapperContent = styled('div')`
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Banner = styled('div')`
    background: url('${banner}') no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: calc(100vh - 80px);
    width: 100%;
    img{
        width: 400px;
        max-width: 100%;
    }
    H2{
        font-size: 2rem;
        text-align: center;
        margin-top: 20px;
    }
`;
