const constants = { 
    colors : {
    blue: '#212c53',
    blueGray: '#303d4e',
    bluelight: '#dae2ec',
    blueshine: '#009ee3',
    green: '#b5bb34',
    greenDark: '#919122',
    black: '#3c3c3b',
    white: '#ffffff',
    gray: '#e6e6e6',
    blackGray: '#1a1a1a'
}}

export default constants;