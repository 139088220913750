import { styled } from "styled-components";
import { useAppContext } from "../Context";

import eeuuflag from '../assets/images/eeuuflag.png';
import spainflag from '../assets/images/spainflag.png';

const LanguageSelector : React.FC = () => {
    const { language, setLanguage} = useAppContext();

    const handleSelectLanguage = (lang : string) =>{
        setLanguage(lang);
    }

    return <LanguageSelectorContainer>
                {language === 'es' ? <LanguageSelectorButton onClick={() => handleSelectLanguage('en')}>
                    <img src={eeuuflag} alt="English" />
                </LanguageSelectorButton> :  <LanguageSelectorButton onClick={() => handleSelectLanguage('es')}>
                <img src={spainflag} alt="English" />
                </LanguageSelectorButton> }
            </LanguageSelectorContainer>;
}

export default LanguageSelector;

const LanguageSelectorContainer = styled('div')`
    position: fixed;
    z-index: 100;
    width: 80px;
    right:20px;
    top: 200px;
    @media (max-width: 920px){
        top: 25px;
        left: 10px;
        width: 50px;
    }
`;

const LanguageSelectorButton = styled('div')`
    width:100%;
    cursor:pointer;
    img{ 
        width: 100%;
        border: 3px solid #fff;
    }
`

