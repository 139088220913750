import { Grid } from "@mui/material";
import  styled from "styled-components";
import { TranslateByKey } from "../components/shared/Translate";

import constants from "../constants";

import logo from "../assets/images/logo-light.png";
import TeamMembers from "../components/team/TeamMembers";

import { TeamMemberDataType } from "../components/team/TeamMembers";

export  const teamData : TeamMemberDataType[]  = [
    {
        "name" : "Julio César Alonso Chocho",
        "position" : "CEO Miami Investment Solutions",
        "experience" : {
            "es" : "M.G.W Home Desing Inc",
            "en" : "M.G.W Home Desing Inc"
        },
        "description" : {
            "es" : "30 años de experiencia en construcción, planificación, financiación y ventas en el Mercado Inmobiliario de Toronto - Canadá, Miami - Florida.",
            "en" : "30 years of experience in construction, planning, financing and sales in the Real Estate Market of Toronto - Canada, Miami - Florida."
        },
        "links": null,
        "image" : "julio-alonso.png",
        "slug" : "julio-alonso",
        "logo" : "logo-mgw.png",
        "company_position" : "CEO - M.G.W Home Desing Inc",
        "multimedia": [
            {
                "type": "video",
                "url": "https://www.youtube.com/watch?v=8jM9V9c0z4Y",
                "description": "406 Drewry Ave - Toronto",
                "poster" : "406-drewry-ave-toronto.jpg"
            },
            {
                "type": "video",
                "url": "https://www.youtube.com/watch?v=8jM9V9c0z4Y",
                "description": "123 Gorman Park RD - Toronto",
                "poster" : "123-gorman-park-rd-toronto.jpg"
            },
            {
                "type": "video",
                "url": "https://www.youtube.com/watch?v=8jM9V9c0z4Y",
                "description": "12885 Weston Road, King, Ontario",
                "poster" : "12885-weston-road-king-ontario.jpg"
            },
            {
                "type": "image",
                "url": "40B-terrace-ave-toronto.jpg",
                "description": "40B Terrace Ave - Toronto"
            }
        ]
    },
    {
        "name" : "Julio Fernando Gonzalez",
        "position" : "Sales Representative Realtor",
        "experience" : null,
        "description" : {
            "es" : "20 años como especialista en venta compra, comercialización y renta de activos inmobiliarios en Miami y toda la Costa Éste y Oeste del Estado de la Florida, Estados Unidos.",
            "en" : "20 years as a specialist in the sale, purchase, marketing and rental of real estate assets in Miami and the entire East and West Coast of the State of Florida, United States."
        },
        "links": null,
        "image" : "julio-gonzalez.png",
        "slug" : "julio-gonzalez"
    },
    {
        "name" : "Ing. Germán Serrano",
        "position" : "Associated Partner",
        "experience" : {
            "es" : "Ingeniero Civil",
            "en" : "Civil Engineer"
        },
        "description" : {
            "es" : "Especialista en gerencia de proyectos de construcción, con 30 años de experiencia en desarrollo de proyectos de infraestructura, obras civiles, Centros Comerciales y Unidades Residenciales de lujo en Colombia.",
            "en" : "Specialist in construction project management, with 30 years of experience in the development of infrastructure projects, civil works, Shopping Centers and Luxury Residential Units in Colombia."
        },
        "links": null,
        "image" : "german-serrano.png",
        "slug" : "german-serrano"
    },
    {
        "name" : "Ing. Ricardo Valderrama",
        "position" : "Associated Partner",
        "experience" : {
            "es" : "Ingeniero Civil",
            "en" : "Civil Engineer"
        },
        "description" : {
            "es" : "Socio - Fundador Y propietario del 50% Constructora Valderrama S.A.S.",
            "en" : "Co - Founder and owner of 50% Constructora Valderrama S.A.S."
        },
        "links": [
            {
                "name" : "Constructora Valderrama S.A.S.",
                "url" : "https://www.constructoravalderrama.com"
            },
            {
                "name" : "Youtube",
                "url" : "https://www.youtube.com/watch?v=Bn5dXrWPdyg&t=19sh"
            },
            {
                "name" : "Instagram",
                "url" : "https://www.instagram.com/constructora_valderrama/"
            },
            {
                "name" : "Wibi",
                "url" : "https://app.wibi.com.co/Ricardovalderrama"
            }
        ],
        "image" : "ricardo-valderrama.png",
        "slug" : "ricardo-valderrama"
    }
];

const Team : React.FC = () => {

    return (
        <TeamContainer id="equipo" >
            <TeamTitleContainer container>
            <Grid item xs={12} sm={4} className="center">
                <img src={logo} alt="logo" className='logo' />
            </Grid>
            <Grid item xs={12} sm={8}>
                <TeamTitle>
                    <TranslateByKey stringKey='team-title' />
                </TeamTitle>
            </Grid>
            </TeamTitleContainer>
            <TeamMembers teamData={teamData} />
        </TeamContainer>
    );
}

export default Team;

const TeamContainer = styled.div`
    padding: 0px 0;
    width: 100%;
    margin-top: 80px;
`;

const TeamTitleContainer = styled(Grid)`
    padding: 100px 0;
    background: ${constants.colors.blueGray};
    color: ${constants.colors.white};
    text-align: center;
    border-radius: 0 0 25px 25px;
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 300px;
        margin: 0 auto;
        max-width: 80%;
    }
`;

const TeamTitle = styled.h2`
    margin-bottom: 30px;
    font-size: 4.5em;
    text-transform: uppercase;

    &::after {
        content: '';
        display: block;
        width: 300px;
        max-width: 80%;
        height: 5px;
        background-color: ${constants.colors.green};
        margin: 20px auto;
    }

    @media (max-width: 768px) {
        font-size: 3em;
    }
`;