import { StringMultilingualType } from "../../Context";
import { useAppContext } from "../../Context";
import { langBook, langBookComponent } from "../../lang";

const Translate : React.FC<{stringText : StringMultilingualType}> = ({ stringText }) => {
    const { language } = useAppContext();
    const text = language === 'es' ? stringText.es : stringText.en;
    return <>{text}</>;
}

export const TranslateByKey : React.FC<{stringKey : string}> = ({ stringKey }) => {
    const { language } = useAppContext();
    const text = language === 'es' ? langBook[stringKey].es : langBook[stringKey].en;
    return <>{text}</>;
}

export const TranslateComponent : React.FC<{stringKey : string}> = ({ stringKey }) => {
    const { language } = useAppContext();
    const componente = language === 'es' ? langBookComponent[stringKey].es : langBookComponent[stringKey].en;
    return <>{componente}</>;
}

export default Translate;